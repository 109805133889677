import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register,resetState } from "../../../reducers/registerSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "../form.css";
import "./register.css";
import Swal from "sweetalert2";
const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { status,error,message } = useSelector((state) => state.register);

  useEffect(() => {   
    if (status === "succeeded") {
      setLoading(false); // Stop loading      
      Swal.fire({
        title: "",
        text: message,
        icon: "success",
        confirmButtonText: "Ok",
      })
      dispatch(resetState());
      navigate("/email-verification", { state: { email: values.email } });
    }else if(status==="failed"){
      setLoading(false);
      setErrors({email:error});
    }
  }, [status, navigate, values.email]);

  const validate = () => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email is invalid";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password is required";
    } else if (values.confirm_password !== values.password) {
      errors.confirm_password = "Passwords do not match";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleRegister = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Start loading
      dispatch(register(values));
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <section>
      <div className="container">
        <div className="form-container">
          <div className="form register">
            <h1 className="form-header">Register</h1>
            <form onSubmit={handleRegister}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name *
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                />
                {errors.name && <p className="error">{errors.name}</p>}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email Address *
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="mb-3 password-field">
                <label htmlFor="password" className="form-label">
                  Password *
                </label>
                <div className="input-with-icon">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                  />
                  <span
                    className="password-toggle"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </span>
                </div>
                {errors.password && <p className="error">{errors.password}</p>}
              </div>
              <div className="mb-3 password-field">
                <label htmlFor="confirm_password" className="form-label">
                  Confirm Password *
                </label>
                <div className="input-with-icon">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirm_password"
                    id="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                    placeholder="Re-enter your password"
                  />
                  <span
                    className="password-toggle"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                  </span>
                </div>
                {errors.confirm_password && <p className="error">{errors.confirm_password}</p>}
              </div>
              <button type="submit" className="form-button" disabled={loading}>
                {loading ? "Loading..." : "Register"}
              </button>
              <p>
                Already have an account? <NavLink to="/login" style={{ float: "inline-end" }} className="link-danger">
                  Login
                </NavLink>
              </p>
             
            </form>
            {/* {loading && <div className="loader">Loading...</div>} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;

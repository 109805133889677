import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "../reducers/loginSlice";
import productSlice from "../reducers/productSlice";
import cardSlice from "../reducers/cardSlice";
import registerSlice from "../reducers/registerSlice";
import otpSlice from "../reducers/otpSlice";
import forgotPasswordSlice from "../reducers/forgotPasswordSlice";

const store = configureStore({
    reducer: {
        login: loginSlice,
        card :cardSlice,
        productItem: productSlice,
        register:registerSlice,
        otpVerify:otpSlice,
        forgotPassword:forgotPasswordSlice,
    },
});

export default store;
import { createBrowserRouter, createRoutesFromElements, } from "react-router-dom"
import { Route } from "react-router-dom"
import Login from "../components/auth/login/Login"
import Register from "../components/auth/register/Register"
import Layout from "../components/layout/Layout"
import Products from "../components/products/Products"
import Carts from "../components/cart/Cart"
import ProductDetail from "../components/productDetail/ProductDetail"
import CheckoutPage from "../components/checkout/CheckoutPage"
import EmailVerification from "../components/auth/otp/EmailVerification"
import Order from "../components/Order/Orders"
import Terms from "../views/staticpages/Terms"
import Privacy from "../views/staticpages/Privacy"
import ForgotPassword from "../components/auth/register/ForgotPassword"
import OrderDetails from "../components/Order/OrderDetails"


export const router = createBrowserRouter(createRoutesFromElements(
<Route path='/' element={<Layout/>}>
<Route index element={<Products/>}></Route>
<Route path='/products' element={<Products/>}></Route>
<Route path='/login' element={<Login/>}></Route>
<Route path='/register' element={<Register/>}></Route>
<Route path='/email-verification' element={<EmailVerification/>}></Route>
<Route path='/cart' element={<Carts/>}></Route>
<Route path='/checkout' element={<CheckoutPage/>}></Route>
<Route path="/product-details/:id" element={<ProductDetail />} />
<Route path="/orders" element={<Order />} />
<Route path="/order-details/:id" element={<OrderDetails />} />
<Route path="/terms" element={<Terms />} />
<Route path="/privacy" element={<Privacy />} />
<Route path="/forgot-password" element={<ForgotPassword />} />
</Route>
))
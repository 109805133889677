import React from 'react'
import { Outlet } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from '../../store/store'
import Header from '../header/Header'
import Footer from '../footer/Footer'
const Layout=()=> {
  return (
    <div>
        <Provider store={store}>
        <Header/>
        <Outlet/>
        <Footer/>
      </Provider>
      
    </div>
  )
}

export default Layout

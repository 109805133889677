import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword ,resetState} from "../../../reducers/forgotPasswordSlice";
import "../form.css";
import "./register.css";
import Swal from "sweetalert2";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({});  
  const [loading, setLoading] = useState(false);
  const { status,error,message } = useSelector((state) => state.forgotPassword);

  useEffect(() => {   
    if (status === "succeeded") {
      setLoading(false);// Stop loading
      Swal.fire({
        title: "",
        text: message,
        icon: "success",
        confirmButtonText: "Ok",
      })
      dispatch(resetState()); 
      navigate("/login");
    }else if(status==="failed"){
      setLoading(false);
      setErrors({email:error});
    }
  }, [status, navigate, values.email]);

  const validate = () => {
    const errors = {};    
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email is invalid";
    }   
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });  };
    const handleRegister = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Start loading
      dispatch(forgotPassword(values));
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <section>
      <div className="container">
        <div className="form-container" style={{minHeight:"70vh"}}>
          <div className="form register">
            <h1 className="form-header">Forgot Password</h1>
            <form onSubmit={handleRegister}>             
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email Address *
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>             
              <button type="submit" className="form-button" disabled={loading}>
                {loading ? "Loading..." : "Send Link"}
              </button>
              <p>
               <NavLink to="/login" style={{ float: "inline-end" }} className="link-danger">
                  Login
                </NavLink>
              </p>
                          
            </form>
       
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;

//import axios from "axios";
//const baseURL='http://localhost/yogamobapp/api/';
const baseURL = "https://api.shivamyogastudio.in/api/";
//const token = localStorage.getItem("token");
const getTokenAsync = async () => {
  let token = localStorage.getItem("token");
  if (!token) {   
    await new Promise(resolve => setTimeout(resolve, 500));
    token = localStorage.getItem("token");
  }
  return token;
};

export const PostFormDataRequest = async (api, data) => {
  const url = baseURL + api;
  const token = await getTokenAsync();
  try {
    const result = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: data,
    });
    if (!result.ok) {
      const errorData = await result.json();
      if (result.status === 422 || result.status === 400 || result.status===404) {
       
       console.log(">>>>>>>>>>>>>>>>>>>>"+errorData);
        return errorData;
      } else {
        return false;
      }
    } else {
      return await result.json();
    }
  } catch (error) {
    console.log("AAAYA HAI BHaiya");
    throw error;
  }
};

export const DeleteByParams = async (api, id) => {
  const url = baseURL + api + "/" + id;
  const token = await getTokenAsync();
  try {
    const result = await fetch(url, {
      method: "delete",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!result.ok) {
      const errorData = await result.json();
      if (result.status === 422 || result.status === 400 || result.status===404) {
        return errorData;
      } else {
        return false;
      }
    } else {
      return await result.json();
    }
  } catch (error) {
    throw error;
  }
};

export const GetAllRequest = async (api) => {
  const token = await getTokenAsync();
  const url = baseURL + api;
  try {
    const result = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!result.ok) {
      const errorData = await result.json();
      if (result.status === 422 || result.status === 400 || result.status===404) {
        return errorData;
      } else {
        return false;
      }
    } else {
      return await result.json();
    }
  } catch (error) {
    throw error;
  }
};

export const GetAllRequestWithParams = async (api, params = {}) => {
  const token = await getTokenAsync();
  const url = new URL(baseURL + api);
  Object.keys(params)
    .filter((key) => params[key] != null)
    .forEach((key) => url.searchParams.append(key, params[key]));
  try {
    const result = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!result.ok) {
      const errorData = await result.json();
      if (result.status === 422 || result.status === 400 || result.status===404) {
        return errorData;
      } else {
        return false;
      }
    } else {
      return await result.json();
    }
  } catch (error) {
    throw error;
  }
};

export const PostFormDataRequestForPayment = async (api, data) => {
  const token = await getTokenAsync();
  const url = baseURL + api;
  try {
    const result = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (!result.ok) {
      const errorData = await result.json();
      if (result.status === 422 || result.status === 400 || result.status===404) {
        return errorData;
      } else {
        return false;
      }
    } else {
      const responseUrl = await result.text();
      return responseUrl;
    }
    // Since the API returns a URL as plain text
  } catch (error) {
    throw error;
  }
};

export const GetRequest = async (api, apiToken) => {
  const token = await getTokenAsync();
  const url = baseURL + api;
  try {
    const result = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!result.ok) {
      const errorData = await result.json();
      if (result.status === 422 || result.status === 400 || result.status===404) {
        return errorData;
      } else {
        localStorage.removeItem("token");
        return false;
      }
    } else {
      return await result.json();
    }
  } catch (error) {
    localStorage.removeItem("token");
  }
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
    color: red;
    font-size: 0.875em; /* small letters */
    margin-top: 5px;
  }
  .input-with-icon {
    position: relative;
  }
  
  .input-with-icon input {
    width: 100%;
    padding-right: 40px; /* Adjust padding to make space for the icon */
  }
  
  .password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #aaa; /* Adjust color as needed */
    font-size: 1.2em;
  }
  
  .password-field {
    position: relative;
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/auth/login/login.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB,EAAE,kBAAkB;IACtC,eAAe;EACjB;EACA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,mBAAmB,EAAE,8CAA8C;EACrE;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,eAAe;IACf,WAAW,EAAE,2BAA2B;IACxC,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".error {\n    color: red;\n    font-size: 0.875em; /* small letters */\n    margin-top: 5px;\n  }\n  .input-with-icon {\n    position: relative;\n  }\n  \n  .input-with-icon input {\n    width: 100%;\n    padding-right: 40px; /* Adjust padding to make space for the icon */\n  }\n  \n  .password-toggle {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n    color: #aaa; /* Adjust color as needed */\n    font-size: 1.2em;\n  }\n  \n  .password-field {\n    position: relative;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, resetState } from "../../../reducers/loginSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "../form.css";
import "./login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    device_type: "web",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { status, error, message } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const errors = {};
    if (!credentials.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
      errors.email = "Email is invalid";
    }
    if (!credentials.password) {
      errors.password = "Password is required";
    } else if (credentials.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginClick = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Start loading
      dispatch(login(credentials));
    } else {
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (status === "succeeded" && token) {
      setLoading(false);
      toast.success(message);
      dispatch(resetState());      
      navigate("/");     
    } else if (status === "failed") {
      setLoading(false);
      toast.error(error);
      dispatch(resetState());
    }
  }, [status, message, error, navigate, dispatch]);

  return (
    <section>
      <div className="container">
        <div className="form-container">
          <div className="form login">
            <h1 className="form-header">Login</h1>
            <form onSubmit={handleLoginClick}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email *
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  value={credentials.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="mb-3 password-field">
                <label htmlFor="password" className="form-label">
                  Password *
                </label>
                <div className="input-with-icon">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    value={credentials.password}
                    onChange={handleChange}
                  />
                  <span
                    className="password-toggle"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </span>
                </div>
                {errors.password && <p className="error">{errors.password}</p>}
              </div>
              <button type="submit" className="form-button">
                {status === "loading" ? "Logging..." : "Login"}
              </button>
              <p>
                <NavLink to="/forgot-password" className="link-danger">
                  Forgot Password?
                </NavLink>
                <NavLink
                  to="/register"
                  className="link-danger"
                  style={{ float: "inline-end" }}
                >
                  Register
                </NavLink>
              </p>
            </form>
          </div>
        </div>
      </div>    
    </section>
  );
};

export default Form;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../reducers/productSlice";
import "./products.css";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import CartEmpty from "../../assets/empty-cart.png";
import axios from "axios";
import filterIcon from "../../assets/filter.svg";
import starIcon from "../../assets/star.svg";
import Swal from "sweetalert2";
import Loader from '../Loader/Loader';
import {
  GetAllRequestWithParams,
  GetAllRequest,
  PostFormDataRequest
} from "../../FormRequest/request";
import { Card } from "react-bootstrap";

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: products, status } = useSelector((state) => state.productItem);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [filterOptions, setFilterOptions] = useState(false);
  const [selectedColor, setSelectedColor] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [sortOption, setSortOption] = useState({
    name: null,
    price: null,
  });
  const [sliderImages, setSliderImage] = useState([]);

  const handleSortChange = (sortType, order) => {
    setSortOption((prev) => ({
      ...prev,
      [sortType]: order,
    }));
  };

  useEffect(() => {
    dispatch(getProducts(sortOption));
  }, [sortOption, dispatch]);

  useEffect(() => {
    sliderImage();
  }, []);

  useEffect(() => {
    if (query.trim() !== "") {
      const delayDebounceFn = setTimeout(() => {
        fetchData(query);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    } else {
     setResults(products);
    }
  }, [query, products]);

  const sliderImage = async () => {
    try {
      const response = await GetAllRequest("listing-event-gallery");
      setSliderImage(response);
    } catch (error) {
      console.error("Error fetching slider images:", error);
    }
  };

  const fetchData = async (searchQuery) => {
    try {
      const params = { search: searchQuery };
      const response = await GetAllRequestWithParams("product-listing", params);
      setResults(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setResults([]);
    }
  };

  if (status === "error") {
    return <p>Something went wrong....</p>;
  }

 

  const addToCard = async (e, product) => {
    e.stopPropagation();
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire({
        title: "Login Required",        
        icon: "info",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
          handleLoginClick(); // Call your login handler here
        }
      });
      return;
    } else {
      try {
      const data= {
          product_id: product.productId,
        }
        await PostFormDataRequest('save-cart',JSON.stringify(data)); 
        } catch (error) {
        console.error("Error adding to cart:", error);
      }
    }
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const applySort = (sortType, order) => {
    handleSortChange(sortType, order);
    setFilterOptions(false);
  };

  const productDetail = (event, product) => {
    event.stopPropagation();
    navigate(`/product-details/${product.id}`);
  };

  const handleFilterOptions = () => {
    setFilterOptions(!filterOptions);
  };

  const card = results.length>0
    ? results.map((product) => (
        <div
          className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
          key={product.id}
          onClick={(event) => productDetail(event, product)}
        >
          <article className="card product">
            <div className="product-images">
              <div className="images">
                <Swiper
                  pagination={{ clickable: true }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {product?.product_image?.map((pi) => (
                    <SwiperSlide key={pi.id}>
                      <div className="image">
                        <img
                          src={`${product.product_image_path}${pi.image}`}
                          alt={product.name}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="ratings">
                <img src={starIcon} /> <strong>{product.average_rating ? parseFloat(product.average_rating).toFixed(1) : 0.0}</strong>
              </div>
            </div>
            <div className="product-info">
              <div className="info">
                <div className="product-title">
                  {product.name.length > 40
                    ? `${product.name.substring(0, 40)}...`
                    : product.name}
                </div>

                {product?.discounted_price ? (
                <div className="pricing">
                   <span className="list-actual-price mr-2"> ₹{product.price}</span>
                  <span className="list-price"> ₹{product.discounted_price}</span>
                                  
                </div>
              ) : (
                <div className="pricing">
                  <span className="list-price"> ₹{product.price}</span>
                </div>
              )}

                <div className="discount">
                  {product.discount && (
                    <h5>
                      <b>{product.discount}% Off</b>
                    </h5>
                  )}
                </div>
                <div
                  className="color-options colors"
                  style={{ justifyContent: "center" }}
                >
                  {/* <div data-color={product.color} title={product.color_name}>
                    {product.color}
                  </div> */}

                  {product?.product_image?.map(
                    (img, index) =>
                      img?.color && (
                        <span
                          key={index}
                          className={`color ${
                            selectedColor === img.color ? "selected" : ""
                          }`}
                          style={{ backgroundColor: img.color }}
                          onClick={() => {
                            setSelectedColor(img.color);
                            const imageData =
                              img?.multiple_images?.length > 0
                                ? img.multiple_images.map((image) => ({
                                    image: `${product?.product_data?.[0]?.productImagePath}/${image.image}`,
                                  }))
                                : [
                                    {
                                      image: `${product?.product_data?.[0]?.productImagePath}/${img.image}`,
                                    },
                                  ];
                            setSelectedImage(imageData);
                          }}
                        />
                      )
                  )}
                </div>
                {/* <button
                  className="addToCart"
                  onClick={(e) => {
                    addToCard(e, product);
                  }}
                >
                  Add to Cart
                </button> */}
              </div>
            </div>
          </article>
        </div>
      ))
    : null;

  return (
   <>
    {(status === "loading") ?(
      <div
      className="cart-product-detail-empty"
    >
    <Loader/>
    </div>
    
    ) :(
    <div className="container">
      <Swiper
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2500, // Delay between slides in milliseconds
          disableOnInteraction: false, // Continue autoplay after user interaction
        }}
        speed={1500}
        modules={[Pagination, Autoplay]}
        className="banners"
        style={{ marginTop: "16px", paddingBottom: "40px" }}
      >
        {sliderImages?.data?.map((pi) => (
          <SwiperSlide key={pi.id}>
            <div className="image image-slider">
              <img
                src={pi.image} 
                alt={pi.name}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="search" id="search">
        <h3 className="title">Yoga Accessories</h3>
        <div className="search-box">
          <input
            type="text"
            placeholder="Search product"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button className="filter" onClick={handleFilterOptions}>
            <img src={filterIcon} />
          </button>
          {filterOptions && (
            <div className="filter-options">
              <ul>
                <li onClick={() => applySort("name", "ascending")}>
                  <label className="filter-item">
                    <span>Name</span>
                    <span>(Ascending)</span>
                  </label>
                </li>
                <li onClick={() => applySort("name", "descending")}>
                  <label className="filter-item">
                    <span>Name</span>
                    <span>(Descending)</span>
                  </label>
                </li>
                <li onClick={() => applySort("price", "ascending")}>
                  <label className="filter-item">
                    <span>Price</span>
                    <span>(Low to High)</span>
                  </label>
                </li>
                <li onClick={() => applySort("price", "descending")}>
                  <label className="filter-item">
                    <span>Price</span>
                    <span>(High to Low)</span>
                  </label>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="row gy-4">{card ? card : <div
        className="cart-product-detail-empty"
        style={{marginTop: "0px;"}}
      > <img src={CartEmpty} alt="Empty Cart" />
            <div className="empty-cart-message">
            Oops! Products are currently unavailable.
            </div>        
        
      </div>}</div>
    </div>
    )      
  }
  </>  
  );
};

export default Products;

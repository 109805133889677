import React from "react";
import { NavLink } from "react-bootstrap";
import "./footer.css";

import Logo from "../../assets/logo.svg";
import inboxIcon from "../../assets/mail.svg";
import youtubeIcon from "../../assets/youtube.png";
import facebookIcon from "../../assets/facebook.svg";
import instagramIcon from "../../assets/instagram.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container links">
        <div className="row">
          <div className="col-sm-12 col-md-4 col-sm-12 columns">
            <div>
              <h3>Legal</h3>
              <ul>
                {/* <li>
                  <NavLink to="">FAQ</NavLink>
                </li> */}
                <li>
                  <NavLink href="/terms">Terms & Conditions</NavLink>
                </li>
                <li>
                  <NavLink href="/privacy">Privacy Policy</NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-sm-12 columns">
            <div>
              <h3>Address</h3>
              <p>
              SHIVAM YOGA STUDIO YA-SCHOOL-RYS 200 / 85 RPYS
              </p>
              <NavLink className="email" href="mailto:shivamyoga@gmail.com" target="_blank">
                <img src={inboxIcon} style={{ marginRight: "20px" }} alt="Inbox Icon" />
                Shivamyoga@gmail.com
              </NavLink>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-sm-12 columns">
            <NavLink to="/">
              <img src={Logo}></img>
            </NavLink>
          </div>
        </div>
      </div>
      <hr style={{ border: "2px solid #29254D61" }} />
      <div className="footer-bottom">
        <div className="container">
          <div>
            <div>
              <ul className="social-icons">
                <li>
                  <NavLink href="https://www.youtube.com/channel/UCB95ewujlU5zM_wwKcALVCA" target="_blank">
                    <img className="youtube-icon" src={youtubeIcon}></img>
                  </NavLink>
                </li>
                <li>
                  <NavLink href="https://www.instagram.com/jeevanyogastudio/" target="_blank">
                    <img src={instagramIcon}></img>
                  </NavLink>
                </li>
                <li>
                  <NavLink href="https://www.facebook.com/jeevanyogastudio360jeevanyogastudio360/" target="_blank">
                    <img src={facebookIcon}></img>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div>
              <p className="copyright">
                Copyright © Shivam Yoga All Right Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
